import {
  PropertyFilterOption,
  useCollection,
} from '@cloudscape-design/collection-hooks';
import {
  PropertyFilterQuery,
  SortingState,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import { useEffect, useMemo } from 'react';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import NoMatchesCollection from '@/components/EmptyCollection/NoMatchesCollection';

import {
  Dataset,
  emptyFilterQuery,
  RootTablePropsOptions,
  TablePreferences,
  TablePropsWithActions,
  TableRecord,
} from '../types';
import { useFixFilterOptions } from '../utils/useFixFilterOptions';
import { useBuildTableProps } from './useBuildTableProps';
import { usePreprocessTableData } from './usePreprocessTableData';

export type UseGetTablePropsOptions<T extends TableRecord> =
  RootTablePropsOptions<T, Dataset<T>>;

export type StatefulTableOptions<T extends TableRecord> = {
  sortingState: SortingState<T> | undefined;
  setSortingState: (sortingState: SortingState<T>) => void;
  propertyFilter: PropertyFilterQuery | undefined;
  setPropertyFilter: (propertyFilter: PropertyFilterQuery) => void;
  preferences: TablePreferences<T> | undefined;
  setPreferences: (preferences: TablePreferences<T> | undefined) => void;
};

type UseGetStatelessTablePropsOptions<T extends TableRecord> =
  UseGetTablePropsOptions<T> & StatefulTableOptions<T>;

export function useGetStatelessTableProps<T extends TableRecord>(
  options: UseGetStatelessTablePropsOptions<T>
): TablePropsWithActions<T> {
  const {
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    setPreferences,
  } = options;
  const {
    columnDefinitions,
    filteringProperties,
    tableData,
    tableFields,
    filteringOptions: extraFilteringOptions,
    preferences,
  } = usePreprocessTableData(options);

  const collection = useCollection(tableData || [], {
    propertyFiltering: {
      filteringProperties,
      noMatch: (
        <NoMatchesCollection
          onClearClick={() => {
            setPropertyFilter(emptyFilterQuery);
            collection.actions.setPropertyFiltering(emptyFilterQuery);
          }}
        />
      ),
      empty: (
        <EmptyEntityCollection
          entityLabel={options.entityLabel}
          action={options.emptyCollectionAction || <></>}
        />
      ),
    },
    pagination: {
      pageSize: preferences?.pageSize,
    },
    sorting: {
      defaultState: options.defaultSortingState
        ? {
            sortingColumn:
              columnDefinitions.find(
                (c) => c.id === options.defaultSortingState?.sortingColumn
              ) ?? columnDefinitions[0],
            isDescending:
              options.defaultSortingState?.sortingDirection === 'desc',
          }
        : {
            sortingColumn: columnDefinitions[0],
          },
    },
  });

  const filteringOptions: PropertyFilterOption[] = useFixFilterOptions(
    collection.propertyFilterProps.filteringOptions
  );

  useEffect(() => {
    if (
      sortingState &&
      (sortingState.isDescending !==
        collection.collectionProps.sortingDescending ||
        JSON.stringify(sortingState.sortingColumn) !==
          JSON.stringify(collection.collectionProps.sortingColumn))
    )
      collection.actions.setSorting(sortingState);
  }, [
    sortingState,
    collection.actions,
    collection.collectionProps.sortingColumn,
    collection.collectionProps.sortingDescending,
  ]);

  useEffect(() => {
    if (
      propertyFilter &&
      JSON.stringify(propertyFilter) !==
        JSON.stringify(collection.propertyFilterProps.query)
    )
      collection.actions.setPropertyFiltering(propertyFilter);
  }, [
    propertyFilter,
    collection.actions,
    collection.propertyFilterProps.query,
  ]);

  const actions = useMemo(
    () => ({ ...collection.actions, setSorting: setSortingState }),
    [collection.actions, setSortingState]
  );

  return useBuildTableProps({
    tableFields,
    allPageItems: collection.allPageItems,
    preferences,
    entityLabel: options.entityLabel,
    columnDefinitions,
    items: collection.items,
    allItems: tableData,
    propertyFilterProps: {
      filteringProperties,
      filteringOptions: [...filteringOptions, ...extraFilteringOptions],
      query: propertyFilter ?? emptyFilterQuery,
      onChange: ({ detail }) => setPropertyFilter(detail),
    },
    // If filters are show before data comes back, the number of columns change once the custom attribute data returns, and relative date format can throw errors
    filtersEnabled: (options.enableFiltering && !!options.data) ?? true,
    paginationProps: collection.paginationProps,
    actions,
    setPreferences,
    onSortingChange: setSortingState,
    onPropertyFilterChange: setPropertyFilter,
    extraProps: collection.collectionProps,
    extraFilters: options.extraFilters,
  });
}
