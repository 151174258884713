import { HandleOptions } from '@risksmart-app/components/hooks/use-breadcrumbs';
import { RouteObject } from 'react-router-dom';
import i18n from 'src/i18n';
import ProtectedErrorPage from 'src/pages/error/ProtectedErrorPage';
import { PublicPoliciesPage } from 'src/pages/policy/public/Page';
import OrgFeatureFlaggedRoute from 'src/rbac/OrgFeatureFlaggedRoute';
import ProtectedRoute from 'src/rbac/ProtectedRoute';

import {
  accessDeniedUrl,
  complianceDashboardUrl,
  impactRatingsUrl,
  impactsUrl,
  internalAuditDashboardUrl,
  loginUrl,
  logoutUrl,
  myItemsUrl,
  policyRegisterUrl,
  publicPoliciesUrl,
  reportAnIssueSuccessUrl,
  reportAnIssueUrl,
  riskDashboardUrl,
  settingsUrl,
  slackCallbackUrl,
  thirdPartyRegisterUrl,
} from '@/utils/urls';

import { ProtectedLayout } from '../layouts';
import {
  AcceptanceCreatePage,
  AcceptancesPage,
  AcceptanceUpdatePage,
  AccessDeniedPage,
  ActionsPage,
  AppetiteBreadcrumb,
  AppetiteCreatePage,
  AppetitesPage,
  AppetiteUpdatePage,
  AssessmentCreatePage,
  AssessmentResultsRegisterPage,
  AssessmentsPage,
  AttestationsPage,
  AuthErrorPage,
  CausesPage,
  ConsequencesPage,
  ControlGroupsPage,
  ControlsPage,
  ControlTestsPage,
  ErrorPage,
  HomePage,
  ImpactBreadcrumb,
  ImpactPage,
  ImpactRatingsPage,
  ImpactsPage,
  IndicatorsPage,
  InternalAuditCreatePage,
  InternalAuditDashboardPage,
  InternalAuditPage,
  InvitationExpiredPage,
  IssuesPage,
  LoginPage,
  LogoutPage,
  MyItemsPage,
  NotFoundPage,
  ObligationCreatePage,
  ObligationDashboardPage,
  ObligationsPage,
  ObligationUpdateBreadcrumb,
  ObligationUpdatePage,
  OpenPublicPolicyFilePage,
  OpenPublicPolicyFileRedirect,
  OrgNotFoundPage,
  PolicyCreatePage,
  PolicyPage,
  ReportAnIssuePage,
  ReportAnIssueSuccessPage,
  ReportsPage,
  RequestsPage,
  RiskDashboardPage,
  RisksCreatePage,
  RisksPage,
  RisksUpdateBreadcrumb,
  RisksUpdatePage,
  SlackCallbackPage,
  ThirdPartyCreatePage,
  ThirdPartyPage,
  ThirdPartyUpdatePage,
  UpdateAssessmentResultPage,
  UpdateInternalAuditResultPage,
  UpdateMonitoringAssessmentsResultPage,
  UserNotFoundPage,
} from '../pages';
import AcceptanceBreadcrumb from '../pages/acceptances/detail/update/Breadcrumb';
import ComplianceMonitoringAssessmentsPage from '../pages/compliance/monitoring-assessments/Page';
import ComplianceMonitoringAssessmentResultsPage from '../pages/compliance/monitoring-assessments/results/Page';
import InternalAuditReportPage from '../pages/internal-audit/reports/Page';
import InternalAuditReportResultsPage from '../pages/internal-audit/reports/results/Page';
import ThirdPartyBreadcrumb from '../pages/third-party/update/Breadcrumb';
import Providers from '../Providers';
import { actionRoute } from './actionRoutes.config';
import { assessmentsRoute } from './assessmentRoutes.config';
import { complianceMonitoringAssessmentRoute } from './complianceMonitoringAssessmentRoutes.config';
import { controlGroupRoute } from './controlGroupRoutes.config';
import { controlRoute } from './controlRoute.routes.config';
import { documentRoute } from './documentRoutes.config';
import { indicatorRoute } from './indicatorRoutes.config';
import { internalAuditReportsRoute } from './internalAuditReportRoutes.config';
import { internalAuditRoute } from './internalAuditRoutes.config';
import { issueRoute } from './issueRoutes.config';
import { settingsRoute } from './settingRoutes.config';

const routes: RouteObject[] = [
  {
    element: <Providers />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: loginUrl(),
        element: <LoginPage />,
        handle: {
          // TODO: translation
          title: 'Login',
        },
      },
      {
        children: [
          {
            path: '/error',
            element: <ErrorPage />,
            handle: {
              // TODO: translation
              title: 'Error',
            },
          },
          {
            path: '/auth-error',
            element: <AuthErrorPage />,
          },
          {
            path: '/noorg',
            element: <OrgNotFoundPage />,
            handle: {
              // TODO: translation
              title: 'No organisation found',
            },
          },
          {
            path: '/user-not-found',
            element: <UserNotFoundPage />,
            handle: {
              // TODO: translation
              title: 'User not found',
            },
          },
          {
            path: '/invitation-expired',
            element: <InvitationExpiredPage />,
            handle: {
              // TODO: translation
              title: 'Invitation expired',
            },
          },
          {
            path: accessDeniedUrl(),
            element: <AccessDeniedPage />,
            handle: {
              // TODO: translation
              title: 'Access denied',
            },
          },
        ],
      },

      {
        path: logoutUrl(),
        element: <LogoutPage loginUrl={loginUrl} />,
        handle: {
          // TODO: translation
          title: 'Logout',
        },
      },
      {
        path: '/',
        element: <ProtectedLayout />,
        errorElement: <ErrorPage />,
        children: [
          {
            errorElement: <ProtectedErrorPage />,
            children: [
              {
                path: slackCallbackUrl(),
                element: <SlackCallbackPage />,
              },
              {
                path: '/',
                element: <HomePage />,
              },
              {
                path: myItemsUrl(),
                element: (
                  <ProtectedRoute permission="read:my_items">
                    <MyItemsPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: riskDashboardUrl(),
                element: (
                  <ProtectedRoute
                    permission="read:risk"
                    canHaveAccessAsContributor={true}
                  >
                    <RiskDashboardPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: reportAnIssueUrl(),
                element: (
                  <ProtectedRoute permission="read:public_issue_form">
                    <ReportAnIssuePage />
                  </ProtectedRoute>
                ),
              },
              {
                path: publicPoliciesUrl(),
                element: (
                  <ProtectedRoute permission={'read:public_policies'}>
                    <PublicPoliciesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: 'policy/:documentId/files/:fileId',
                element: <OpenPublicPolicyFileRedirect />,
              },
              {
                path: `${publicPoliciesUrl()}/:documentId/files/:fileId`,
                element: (
                  <ProtectedRoute
                    permission={['read:public_policies', 'read:document']}
                  >
                    <OpenPublicPolicyFilePage />
                  </ProtectedRoute>
                ),
              },
              {
                path: settingsUrl(),
                children: [settingsRoute],
              },
              {
                path: reportAnIssueSuccessUrl(),
                element: (
                  <ProtectedRoute permission="read:public_issue_form">
                    <ReportAnIssueSuccessPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: policyRegisterUrl(),
                handle: {
                  title: () => i18n.t('policy.policy'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag="policy">
                        <ProtectedRoute
                          permission="read:document"
                          canHaveAccessAsContributor={true}
                        >
                          <PolicyPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag="policy">
                        <ProtectedRoute permission="insert:document">
                          <PolicyCreatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('policy.create_title'),
                    },
                  },
                  documentRoute,
                ],
              },
              {
                path: `${policyRegisterUrl()}/attestations`,
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'attestations'}>
                    <ProtectedRoute permission={'read:attestation_record'}>
                      <AttestationsPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/controls',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('control_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:control"
                        canHaveAccessAsContributor={true}
                      >
                        <ControlsPage />
                      </ProtectedRoute>
                    ),
                  },
                  controlRoute,
                ],
              },
              {
                path: '/controls/tests',
                element: (
                  <ProtectedRoute
                    permission="read:control"
                    canHaveAccessAsContributor={true}
                  >
                    <ControlTestsPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: '/control-groups',
                handle: {
                  // TODO: translation
                  title: 'Control Groups',
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:control_group"
                        canHaveAccessAsContributor={true}
                      >
                        <ControlGroupsPage />
                      </ProtectedRoute>
                    ),
                  },
                  controlGroupRoute,
                ],
              },
              {
                path: '/indicator',
                handle: {
                  title: () =>
                    // TODO: translation
                    'Indicators',
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:indicator"
                        canHaveAccessAsContributor={true}
                      >
                        <IndicatorsPage />
                      </ProtectedRoute>
                    ),
                  },
                  indicatorRoute,
                ],
              },
              {
                path: '/assessments/findings',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentResultsRegisterPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission="read:assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateAssessmentResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/assessments',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('assessment_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute permission="insert:assessment">
                        <AssessmentCreatePage assessmentMode={'rating'} />
                      </ProtectedRoute>
                    ),
                    handle: {
                      // TODO: translation
                      title: 'Add assessment',
                    },
                  },
                  assessmentsRoute,
                ],
              },
              {
                path: internalAuditDashboardUrl(),
                element: (
                  <OrgFeatureFlaggedRoute featureFlag="internal_audit">
                    <ProtectedRoute
                      permission="read:internal_audit_entity"
                      canHaveAccessAsContributor={true}
                    >
                      <InternalAuditDashboardPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/internal-audits',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('internal_audit_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:internal_audit_entity"
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission="insert:internal_audit_entity"
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditCreatePage />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('internalAudits.add_button'),
                    },
                  },
                  internalAuditRoute,
                ],
              },
              {
                path: '/internal-audits/reports',
                handle: {
                  title: () =>
                    i18n.format(
                      i18n.t('internal_audit_report_other'),
                      'capitalize'
                    ),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:internal_audit_report"
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditReportPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission="insert:internal_audit_report"
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentCreatePage
                          assessmentMode={'internal_audit_report'}
                        />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () => i18n.t('internalAuditReports.add_button'),
                    },
                  },
                  internalAuditReportsRoute,
                ],
              },

              {
                path: '/internal-audits/reports/findings',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:internal_audit_report"
                        canHaveAccessAsContributor={true}
                      >
                        <InternalAuditReportResultsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission="read:assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateInternalAuditResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/compliance/monitoring-assessments',
                handle: {
                  title: () =>
                    i18n.format(
                      i18n.t('compliance_monitoring_assessment_other'),
                      'capitalize'
                    ),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:compliance_monitoring_assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <ComplianceMonitoringAssessmentsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission="insert:compliance_monitoring_assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <AssessmentCreatePage
                          assessmentMode={'compliance_monitoring_assessment'}
                        />
                      </ProtectedRoute>
                    ),
                    handle: {
                      title: () =>
                        i18n.t('complianceMonitoringAssessment.add_button'),
                    },
                  },
                  complianceMonitoringAssessmentRoute,
                ],
              },
              {
                path: '/compliance/monitoring-assessments/findings',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('finding_other'), 'capitalizeAll'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:compliance_monitoring_assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <ComplianceMonitoringAssessmentResultsPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':findingId',
                    handle: {
                      title: () =>
                        i18n.format(i18n.t('finding_one'), 'capitalizeAll'),
                    },
                    element: (
                      <ProtectedRoute
                        permission="read:assessment"
                        canHaveAccessAsContributor={true}
                      >
                        <UpdateMonitoringAssessmentsResultPage />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: '/third-party',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('third_party_other'), 'capitalizeAll'),
                  breadcrumbUrl: () => thirdPartyRegisterUrl(),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute
                          permission={'read:third_party'}
                          canHaveAccessAsContributor={true}
                        >
                          <ThirdPartyPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    handle: {
                      title: () => i18n.t('common:third_party.create_title'),
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                        <ProtectedRoute permission={'insert:third_party'}>
                          <ThirdPartyCreatePage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: ':id',
                    handle: {
                      breadcrumb: ThirdPartyBreadcrumb,
                    },
                    children: [
                      {
                        path: '',
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                            <ProtectedRoute permission={'read:third_party'}>
                              <ThirdPartyUpdatePage
                                activeTabId={'details'}
                                showDeleteButton={true}
                              />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'questionnaires',
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                            <ProtectedRoute permission={'read:third_party'}>
                              <ThirdPartyUpdatePage
                                activeTabId={'questionnaires'}
                              />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'controls',
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute permission={'read:control'}>
                                  <ThirdPartyUpdatePage
                                    activeTabId={'controls'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          controlRoute,
                        ],
                      },
                      {
                        path: 'issues',
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute permission={'read:issue'}>
                                  <ThirdPartyUpdatePage
                                    activeTabId={'issues'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          issueRoute,
                        ],
                      },
                      {
                        path: 'actions',
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute
                                featureFlag={'third_party'}
                              >
                                <ProtectedRoute permission={'read:action'}>
                                  <ThirdPartyUpdatePage
                                    activeTabId={'actions'}
                                  />
                                </ProtectedRoute>
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          actionRoute,
                        ],
                      },
                      {
                        path: 'linked-items',
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag={'third_party'}>
                            <ProtectedRoute permission={'read:linked_item'}>
                              <ThirdPartyUpdatePage
                                activeTabId={'linkedItems'}
                              />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: '/actions',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('action_other'), 'capitalize'),
                },
                children: [
                  {
                    path: '',
                    element: (
                      <ProtectedRoute
                        permission="read:action"
                        canHaveAccessAsContributor={true}
                      >
                        <ActionsPage />
                      </ProtectedRoute>
                    ),
                  },
                  actionRoute,
                ],
              },
              {
                path: '/causes',
                element: (
                  <ProtectedRoute
                    permission="read:cause"
                    canHaveAccessAsContributor={true}
                  >
                    <CausesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: '/consequences',
                element: (
                  <ProtectedRoute
                    permission="read:consequence"
                    canHaveAccessAsContributor={true}
                  >
                    <ConsequencesPage />
                  </ProtectedRoute>
                ),
              },
              {
                path: '/issues',
                handle: {
                  title: () => i18n.t('issues.tab_title'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:issue"
                        canHaveAccessAsContributor={true}
                      >
                        <IssuesPage />
                      </ProtectedRoute>
                    ),
                  },

                  issueRoute,
                ],
              },
              {
                path: '/acceptances',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('acceptance_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:acceptance"
                        canHaveAccessAsContributor={true}
                      >
                        <AcceptancesPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':acceptanceId',
                    handle: {
                      breadcrumb: AcceptanceBreadcrumb,
                    },
                    element: <AcceptanceUpdatePage />,
                  },
                ],
              },
              {
                path: '/appetites',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('appetite_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:appetite"
                        canHaveAccessAsContributor={true}
                      >
                        <AppetitesPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: ':appetiteId',
                    handle: {
                      breadcrumb: AppetiteBreadcrumb,
                    },
                    element: <AppetiteUpdatePage />,
                  },
                ],
              },
              {
                path: impactRatingsUrl(),
                children: [
                  {
                    index: true,
                    handle: {
                      title: () =>
                        i18n.format(
                          i18n.t('impact_rating_other'),
                          'capitalize'
                        ),
                    },
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag="impacts">
                        <ProtectedRoute
                          permission="read:impact_rating"
                          canHaveAccessAsContributor={true}
                        >
                          <ImpactRatingsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                ],
              },
              {
                path: impactsUrl(),
                handle: {
                  title: () =>
                    i18n.format(i18n.t('impact_other'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag="impacts">
                        <ProtectedRoute permission="read:impact">
                          <ImpactsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: ':impactId',
                    handle: {
                      breadcrumb: ImpactBreadcrumb,
                    },
                    children: [
                      {
                        index: true,
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag="impacts">
                            <ProtectedRoute permission="read:impact">
                              <ImpactPage activeTabId="details" />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: 'rating',
                        handle: {
                          title: () =>
                            i18n.format(i18n.t('rating_other'), 'capitalize'),
                        },
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag="impacts">
                            <ProtectedRoute permission="read:impact">
                              <ImpactPage activeTabId="ratings" />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: '/reports',
                element: (
                  <OrgFeatureFlaggedRoute featureFlag="reports">
                    <ReportsPage />
                  </OrgFeatureFlaggedRoute>
                ),
                handle: {
                  // TODO: translation
                  title: 'Reports',
                },
              },
              {
                path: complianceDashboardUrl(),
                element: (
                  <OrgFeatureFlaggedRoute featureFlag="compliance">
                    <ProtectedRoute
                      permission="read:obligation"
                      canHaveAccessAsContributor={true}
                    >
                      <ObligationDashboardPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/compliance',
                handle: {
                  breadcrumbUrl: (options: HandleOptions) => {
                    if (
                      options.location.state?.from === 'compliance-dashboard'
                    ) {
                      return complianceDashboardUrl();
                    }
                    return '/compliance';
                  },
                  title: () => i18n.format(i18n.t('compliance'), 'capitalize'),
                },
                children: [
                  {
                    index: true,
                    element: (
                      <OrgFeatureFlaggedRoute featureFlag="compliance">
                        <ProtectedRoute
                          permission="read:obligation"
                          canHaveAccessAsContributor={true}
                        >
                          <ObligationsPage />
                        </ProtectedRoute>
                      </OrgFeatureFlaggedRoute>
                    ),
                  },
                  {
                    path: 'obligation',
                    handle: { isNotParent: true },
                    children: [
                      {
                        index: true,
                        handle: {
                          // TODO: translation
                          title: 'Add Obligation',
                          isNotParent: true,
                        },
                        element: (
                          <OrgFeatureFlaggedRoute featureFlag="compliance">
                            <ProtectedRoute permission="insert:obligation">
                              <ObligationCreatePage />
                            </ProtectedRoute>
                          </OrgFeatureFlaggedRoute>
                        ),
                      },
                      {
                        path: ':obligationId',
                        handle: {
                          // TODO: translation
                          title: 'Edit Obligation',
                          breadcrumb: ObligationUpdateBreadcrumb,
                        },
                        children: [
                          {
                            path: '',
                            element: (
                              <OrgFeatureFlaggedRoute featureFlag="compliance">
                                <ObligationUpdatePage selectedTabId="details" />
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          {
                            path: 'impact',
                            handle: {
                              // TODO: translation
                              title: 'Impact',
                            },
                            element: (
                              <OrgFeatureFlaggedRoute featureFlag="compliance">
                                <ObligationUpdatePage selectedTabId="impact" />
                              </OrgFeatureFlaggedRoute>
                            ),
                          },
                          {
                            path: 'ratings',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('assessment_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <OrgFeatureFlaggedRoute featureFlag="compliance">
                                    <ObligationUpdatePage selectedTabId="ratings" />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              assessmentsRoute,
                            ],
                          },
                          {
                            path: 'actions',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('action_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <OrgFeatureFlaggedRoute featureFlag="compliance">
                                    <ObligationUpdatePage selectedTabId="actions" />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              actionRoute,
                            ],
                          },
                          {
                            path: 'issues',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('issue_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <OrgFeatureFlaggedRoute featureFlag="compliance">
                                    <ObligationUpdatePage selectedTabId="issues" />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              issueRoute,
                            ],
                          },
                          {
                            path: 'controls',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('control_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <OrgFeatureFlaggedRoute featureFlag="compliance">
                                    <ObligationUpdatePage selectedTabId="controls" />
                                  </OrgFeatureFlaggedRoute>
                                ),
                              },
                              controlRoute,
                            ],
                          },
                          {
                            path: 'linked-items',
                            handle: {
                              title: 'Linked items',
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <ObligationUpdatePage selectedTabId="linkedItems" />
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '/requests',
                handle: {
                  title: () =>
                    i18n.format(i18n.t('request_other'), 'capitalize'),
                },
                element: (
                  <OrgFeatureFlaggedRoute featureFlag={'approvers'}>
                    <ProtectedRoute
                      permission="read:change_request"
                      canHaveAccessAsContributor={true}
                    >
                      <RequestsPage />
                    </ProtectedRoute>
                  </OrgFeatureFlaggedRoute>
                ),
              },
              {
                path: '/risks',
                handle: {
                  breadcrumbUrl: (options: HandleOptions) => {
                    if (options.location.state?.from === 'risk-dashboard') {
                      return riskDashboardUrl();
                    }
                    return '/risks';
                  },
                  title: () => i18n.format(i18n.t('risk_other'), 'capitalize'),
                },

                children: [
                  {
                    index: true,
                    element: (
                      <ProtectedRoute
                        permission="read:risk"
                        canHaveAccessAsContributor={true}
                      >
                        <RisksPage />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: 'add',
                    element: (
                      <ProtectedRoute
                        permission="insert:risk"
                        canHaveAccessAsContributor={true}
                      >
                        <RisksCreatePage />
                      </ProtectedRoute>
                    ),
                    handle: {
                      // TODO: translation
                      title: 'Add Risk',
                    },
                  },
                  {
                    path: ':riskId',
                    handle: {
                      // TODO: translation
                      title: 'Edit Risk',
                      breadcrumb: RisksUpdateBreadcrumb,
                    },
                    children: [
                      {
                        path: '',
                        children: [
                          {
                            path: '',
                            element: (
                              <ProtectedRoute
                                permission="read:risk"
                                canHaveAccessAsContributor={true}
                              >
                                <RisksUpdatePage
                                  selectedTabId="details"
                                  showDeleteButton={true}
                                />
                              </ProtectedRoute>
                            ),
                          },
                          {
                            path: 'ratings',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('rating_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId="ratings" />
                                ),
                              },
                              assessmentsRoute,
                            ],
                          },
                          {
                            path: 'impacts',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('impact_other'),
                                  'capitalize'
                                ),
                            },
                            element: (
                              <RisksUpdatePage selectedTabId="impacts" />
                            ),
                          },
                          {
                            path: 'actions',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('action_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId="actions" />
                                ),
                              },
                              actionRoute,
                            ],
                          },
                          {
                            path: 'controls',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('control_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId="controls" />
                                ),
                              },
                              controlRoute,
                            ],
                          },
                          {
                            path: 'appetite',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('appetite_one'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <RisksUpdatePage selectedTabId="appetites" />
                                ),
                              },
                              {
                                path: 'add',
                                handle: {
                                  title: () =>
                                    i18n.format(
                                      i18n.t('appetites.create_modal_title'),
                                      'capitalize'
                                    ),
                                },
                                element: <AppetiteCreatePage />,
                              },
                              {
                                path: ':appetiteId',
                                handle: {
                                  breadcrumb: AppetiteBreadcrumb,
                                },
                                element: <AppetiteUpdatePage />,
                              },
                            ],
                          },
                          {
                            path: 'acceptances',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('acceptance_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                index: true,
                                element: (
                                  <RisksUpdatePage selectedTabId="acceptances" />
                                ),
                              },
                              {
                                path: 'add',
                                handle: {
                                  title: () =>
                                    i18n.format(
                                      i18n.t('acceptances.create_modal_title'),
                                      'capitalize'
                                    ),
                                },
                                element: <AcceptanceCreatePage />,
                              },
                              {
                                path: ':acceptanceId',
                                handle: {
                                  breadcrumb: AcceptanceBreadcrumb,
                                },
                                element: <AcceptanceUpdatePage />,
                              },
                            ],
                          },
                          {
                            path: 'indicators',
                            handle: {
                              title: () =>
                                i18n.format(
                                  i18n.t('indicator_other'),
                                  'capitalize'
                                ),
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId="indicators" />
                                ),
                              },
                              indicatorRoute,
                            ],
                          },
                          {
                            path: 'approvals',
                            handle: {
                              title: () => i18n.t('approvals.tab_title'),
                            },
                            element: (
                              <RisksUpdatePage selectedTabId="approvals" />
                            ),
                          },
                          {
                            path: 'linked-items',
                            handle: {
                              // translations
                              title: 'Linked items',
                            },
                            children: [
                              {
                                path: '',
                                element: (
                                  <RisksUpdatePage selectedTabId="linkedItems" />
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: '*',
                element: <NotFoundPage />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
];
export default routes;
