import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetOwnersFieldConfig } from '@/utils/table/hooks/useGetOwnersFieldConfig';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { publicPolicyFileUrl } from '@/utils/urls';

import { DocumentFile, DocumentFileTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<DocumentFileTableFields> => {
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );
  const { getByValue } = useRating('document_file_status');
  const { t } = useTranslation('common');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.columns',
  });

  const getLink = (item: DocumentFileTableFields, label: string) => {
    return (
      <Link
        variant="secondary"
        href={publicPolicyFileUrl(item.ParentDocumentId)}
      >
        {label}
      </Link>
    );
  };

  return {
    Title: {
      header: t('document_one'),
      cell: (item) => getLink(item, item.Title),
      isRowHeader: true,
    },
    Version: {
      header: st('version'),
      cell: (item) => getLink(item, item.Version),
      isRowHeader: true,
    },
    TypeLabel: {
      header: st('type'),
    },
    StatusLabelled: {
      header: st('status'),
      cell: (item) => {
        return <SimpleRatingBadge rating={getByValue(item.Status)} />;
      },
    },
    AttestationStatusLabel: {
      header: st('attestationStatus'),
      cell: (item) =>
        item.AttestationStatus ? (
          <SimpleRatingBadge
            rating={getAttestationRecordStatusByValue(item.AttestationStatus)}
          />
        ) : (
          '-'
        ),
    },
    Summary: {
      header: st('summary'),
    },
    allOwners: useGetOwnersFieldConfig<DocumentFileTableFields>(),
    ReviewDate: dateColumn(st('reviewDate'), 'ReviewDate'),
    ReviewDue: dateColumn(st('reviewDue'), 'ReviewDue'),
    ModifiedAtTimestamp: dateColumn(st('updatedOn'), 'ModifiedAtTimestamp'),
  };
};

export const useGetCollectionTableProps = (
  records: DocumentFile[] | undefined
): TablePropsWithActions<DocumentFileTableFields> => {
  const { t: pt } = useTranslation('common', {
    keyPrefix: 'policy',
  });

  const labelledFields = useLabelledFields(records);

  const fields = useGetFieldConfig();

  return useGetTableProps({
    data: labelledFields,
    entityLabel: pt('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'MyPolicies-Preferences',
    enableFiltering: true,
    initialColumns: [
      'Title',
      'Version',
      'TypeLabel',
      'StatusLabelled',
      'AttestationStatusLabel',
      'Summary',
      'allOwners',
      'ReviewDate',
      'ReviewDue',
      'ModifiedAtTimestamp',
    ],
    fields,
  });
};
