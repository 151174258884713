import { CardsProps } from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { publicPolicyFileUrl } from '@/utils/urls';

import Cards from '../../../components/Cards';
import { DocumentFileTableFields } from './types';

const CardView: FC<
  Omit<CardsProps<DocumentFileTableFields>, 'cardDefinition'>
> = (props) => {
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.columns',
  });

  return (
    <Cards
      {...props}
      cardDefinition={{
        header: (item) => (
          <div className="flex">
            <div className="grow">
              <Link
                href={publicPolicyFileUrl(item.ParentDocumentId)}
                variant="secondary"
                fontSize="heading-m"
              >
                {item.Title}
              </Link>
            </div>
            {item.AttestationStatus && (
              <SimpleRatingBadge
                rating={getAttestationRecordStatusByValue(
                  item.AttestationStatus
                )}
              />
            )}
          </div>
        ),
        sections: [
          {
            id: 'summary',
            content: (item) => (
              <div className="flex gap-4">
                <div>
                  {st('updatedOn')}:{' '}
                  <span className="font-semibold">
                    {toLocalDate(item.ModifiedAtTimestamp)}
                  </span>
                </div>
                <div>
                  {st('version')}:{' '}
                  <span className="font-semibold">{item.Version}</span>
                </div>
              </div>
            ),
          },
          {
            id: 'owners',
            content: (item) => (
              <div className="flex flex-wrap gap-y-3 gap-x-3">
                {item.allOwners.map((owner, i) => (
                  <div
                    key={i}
                    className="px-3 py-1 bg-grey150 text-grey650 rounded-full align-center items-stretch"
                  >
                    {owner.label || ''}
                  </div>
                ))}
              </div>
            ),
          },
        ],
      }}
      cardsPerRow={[
        { cards: 1 },
        { minWidth: 500, cards: 2 },
        { minWidth: 1200, cards: 4 },
      ]}
      trackBy="Id"
    />
  );
};

export default CardView;
