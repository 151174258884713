import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import { useRating } from '@/hooks/use-rating';

import { DocumentFile, DocumentFileTableFields } from './types';

export const useLabelledFields = (
  records: DocumentFile[] | undefined
): DocumentFileTableFields[] | undefined => {
  const { t: pt } = useTranslation('common', {
    keyPrefix: 'policy',
  });
  const documentTypes = pt('types', { returnObjects: true });
  const { getLabel: getDocumentFileStatusLabel } = useRating(
    'document_file_status'
  );
  const { getByValue: getAttestationRecordStatusByValue } = useRating(
    'attestation_record_status'
  );

  return useMemo<DocumentFileTableFields[] | undefined>(
    () =>
      records?.map((d) => {
        const attestation = d.attestations?.[0];
        return {
          ModifiedAtTimestamp: d.ModifiedAtTimestamp,
          Id: d.Id,
          Type: d.Type,
          TypeLabel:
            documentTypes[d.parent?.DocumentType as keyof typeof documentTypes],
          Title: d.parent?.Title || '-',
          Status: d.Status ?? '',
          StatusLabelled: getDocumentFileStatusLabel(d.Status ?? ''),
          ReviewDue: d.NextReviewDate || '-',
          ReviewDate: d.ReviewDate || '-',
          FileId: d.file?.Id,
          Link: d.Link,
          Version: d.Version,
          Summary: d.Summary || '-',
          ParentDocumentId: d.ParentDocumentId,
          allOwners: getAllOwnersCellValue(d.parent!),
          AttestationStatusLabel: attestation
            ? getAttestationRecordStatusByValue(attestation.AttestationStatus)
                ?.label ?? '-'
            : '-',
          AttestationStatus: attestation?.AttestationStatus,
        };
      }),
    [
      records,
      documentTypes,
      getDocumentFileStatusLabel,
      getAttestationRecordStatusByValue,
    ]
  );
};
