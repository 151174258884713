import {
  SegmentedControl,
  SpaceBetween,
} from '@cloudscape-design/components-themed';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

export type View = 'card' | 'table';

const ViewSelector: FC<{
  selectedView: View;
  onSelectedViewChanged: (view: View) => void;
}> = ({ selectedView, onSelectedViewChanged }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.viewSelector}>
      <SpaceBetween size="s" alignItems="end">
        <SegmentedControl
          selectedId={selectedView}
          onChange={({ detail }) =>
            onSelectedViewChanged(detail.selectedId as View)
          }
          options={[
            { text: t('viewSelector.table'), id: 'table' },
            { text: t('viewSelector.card'), id: 'card' },
          ]}
        />
      </SpaceBetween>
    </div>
  );
};

export default ViewSelector;
